<template>
    <v-container fluid fill-height style="justify-content: center">
        <div>
            <alert ref="alertComponent"></alert>
            <playlist-form ref="playlistForm" buttonText="Update" @submit="submit($event)"></playlist-form>
        </div>
    </v-container>
</template>

<script>
import Alert from "../../components/Alert.component";
import PlaylistForm from "../../components/PlaylistForm.component";
import Services from '../../services/api';

export default {
    name: "Playlist",
    components: {Alert, PlaylistForm},
    data: () => ({
        playlist: {}
    }),
    async mounted() {
        this.id = parseInt(this.$route.params.id);
        try {
            this.playlist = await Services.Playlists.getPlaylist(this.id);
        } catch (e) {
            this.$refs.alertComponent.showErrorMessage(e.message);
        }
        this.$refs.playlistForm.setPlaylist(this.playlist);
    },
    methods: {
        /**
         * send the update request to the api
         * @returns {Promise<void>}
         */
        async submit(payload) {
            try {
                this.playlist = await Services.Playlists.updatePlaylist(payload);

                this.$refs.alertComponent.showSuccessMessage("Playlist successfully updated");
                this.$refs.playlistForm.setPlaylist(this.playlist);
            } catch (e) {
                this.showError(e.message);
            }
            this.$refs.playlistForm.resetDefault();
        },

        showError(msg) {
            this.$refs.alertComponent.showErrorMessage(msg);
        }
    }
}
</script>

<style scoped>

</style>